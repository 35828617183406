<template>
  <section
    v-if="mappedData"
    id="dashboard-ecommerce"
  >
    <b-row class="match-height">
      <b-table
        responsive="sm"
        :items="dataProduct"
      />
      <b-col lg="12">
        <b-row class="match-height mb-2">
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <flat-pickr
              v-model="rangeDate"
              placeholder="Choisissez une date"
              class="form-control mr-1"
              :config="{ mode: 'range'}"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="3"
        md="3"
        xs="12"
      >
        <statistic-card-vertical
          color="info"
          icon="DollarsIcon"
          :statistic="`${mappedData.productSoldTotal? mappedData.productSoldTotal : 0}€`"
          statistic-title="CA (hors B2B)"
        />
      </b-col>
      <b-col
        lg="3"
        md="3"
        xs="12"
      >
        <statistic-card-vertical
          color="warning"
          icon="TrendingUpIcon"
          :statistic="`${mappedData.productCostTotal}€`"
          :statistic-title="`Coût Fab. (${mappedData.productCostTotalPercent}%)`"
        />
      </b-col>
      <b-col
        lg="3"
        md="3"
        xs="12"
      >
        <statistic-card-vertical
          color="danger"
          icon="TrendingUpIcon"
          :statistic="`${mappedData.productLogTotal}€`"
          :statistic-title="`Coût Log. (${mappedData.productLogTotalPercent}%)`"
        />
      </b-col>
      <b-col
        lg="3"
        md="3"
        xs="12"
      >
        <statistic-card-vertical
          color="primary"
          icon="TrendingUpIcon"
          :statistic="`${mappedData.productMarketTotal}€`"
          :statistic-title="`Coût Market. (${mappedData.productMarketTotalPercent}%)`"
        />
      </b-col>
      <b-col
        lg="3"
        md="3"
        xs="12"
      >
        <statistic-card-vertical
          color="success"
          icon="TrendingUpIcon"
          :statistic="`${mappedData.returnTotal}€`"
          :statistic-title="`Retour. (${mappedData.returnTotalPercent}%)`"
        />
      </b-col>
      <b-col
        lg="3"
        md="3"
        xs="12"
      >
        <statistic-card-vertical
          color="success"
          icon="TrendingUpIcon"
          :statistic="`${mappedData.productMargeTotal}€`"
          :statistic-title="`Marge. (${mappedData.productMargeTotalPercent}%)`"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <b-card>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Marketing
              </b-card-title>
              <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
            </div>
          </b-card-header>
          <!-- chart -->
          <b-card-body v-if="metricsData">
            <b-row>
              <b-col
                v-if="metricsData && metricsData.length"
                cols="4"
              >
                <download-csv
                  :data="metricsData"
                  name="metricsData.csv">
                  <b-button class="mb-2" variant="warning">Télécharger</b-button>
                </download-csv>
              </b-col>
            </b-row>
            <!-- <b-table
              responsive="sm"
              :items="dataChartLtv.datasets.map((d, key) => ({ key: d.key, ca: {...d.data}, ...d.ca }))"
            /> -->
            <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="metricsData"
              responsive
              :fields="tableColumnsMarketing"
              primary-key="id"
              show-empty
            >
              <template #cell(cost)="cell">
                <span>{{ cell.item.cost.toFixed(1) }}€</span>
                <br>
                <span class="text-primary">{{ ((cell.item.cost * 100) / mappedData.productSoldTotal).toFixed(1) }}% CA Glob. / {{ ((cell.item.cost * 100) / mappedData.productMarketTotal).toFixed(1) }}% Spend Total.</span>
              </template>
            </b-table>

          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        xl="12"
        md="12"
      >
        <b-card>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Catégorie
              </b-card-title>
              <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
            </div>
          </b-card-header>
          <!-- chart -->
          <b-card-body v-if="transactionData">
            <!-- <b-table
              responsive="sm"
              :items="dataChartLtv.datasets.map((d, key) => ({ key: d.key, ca: {...d.data}, ...d.ca }))"
            /> -->
            <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="transactionData"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Aucune commande trouvée"
            >
              <template #cell(payment)="cell">
                <span>{{ cell.item.payment.toFixed(1) }}€</span>
                <br>
                <span class="text-warning">{{ ((cell.item.payment * 100) / mappedData.productSoldTotal).toFixed(1) }}% CA Global.</span>
              </template>
              <template #cell(cost)="cell">
                <span>{{ cell.item.cost.toFixed(1) }}€</span>
                <br>
                <span class="text-primary">{{ ((cell.item.cost * 100) / cell.item.payment).toFixed(1) }}% CA Cat.</span>
              </template>
              <template #cell(log)="cell">
                <span>{{ (cell.item.log).toFixed(1) }}€</span>
                <br>
                <span class="text-primary">{{ ((cell.item.log * 100) / cell.item.payment).toFixed(1) }}% CA Cat.</span>
              </template>
              <template #cell(margin)="cell">
                <span>{{ (cell.item.payment - cell.item.cost - (cell.item.log)).toFixed(1) }}€</span>
                <br>
                <span class="text-success">{{ (((cell.item.payment - cell.item.cost - (cell.item.log)) * 100) / cell.item.payment).toFixed(1) }}%</span>
              </template>
              <template #cell(type)="cell">
                <b-badge
                  pill
                  :variant="cell.item.type === 'BigProduct' ? 'warning' : 'primary'"
                >
                  {{ cell.item.type }}
                </b-badge>
              </template>
            </b-table>

          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        xl="12"
        md="12"
      >
        <b-card>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Produits
              </b-card-title>
              <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
            </div>
          </b-card-header>
          <!-- chart -->
          <b-card-body v-if="transactionData">
            <b-row>
              <b-col :md="8">
                <v-select
                  v-model="productList"
                  placeholder="Produit"
                  label="name"
                  :options="productSelect"
                  :reduce="product => product.name"
                  :clearable="false"
                  :multiple="true"
                  class="invoice-filter-select mb-2"
                  transition=""
                />
              </b-col>
              <b-col
                v-if="transactionData && transactionData.length"
                cols="4"
              >
                <download-csv
                  :data="productsData.map(p => ({...p, cost: p.cost ? (p.cost).toFixed(0) : 0, log: p.log ? Number(p.log).toFixed(0) : 0, ca: p.ca ? Number(p.ca).toFixed(0) : 0 }))"
                  name="dataProduct.csv">
                  <b-button class="mb-2" variant="warning">Télécharger</b-button>
                </download-csv>
              </b-col>
            </b-row>
            <!-- <b-table
              responsive="sm"
              :items="dataChartLtv.datasets.map((d, key) => ({ key: d.key, ca: {...d.data}, ...d.ca }))"
            /> -->
            <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="productsData"
              responsive
              :fields="tableColumnsProduct"
              primary-key="id"
              show-empty
              empty-text="Aucune commande trouvée"
            >
              <template #cell(ca)="cell">
                <span>{{ cell.item.ca.toFixed(0) }}€</span>
                <!-- <br>
                <span class="text-warning">{{ ((cell.item.ca * 100) / mappedData.productSoldTotal).toFixed(1) }}% CA Global.</span> -->
              </template>
              <template #cell(cost)="cell">
                <span>{{ cell.item.cost.toFixed(0) }}€</span>
                <br>
                <span class="text-primary">{{ ((cell.item.cost * 100) / cell.item.ca).toFixed(1) }}% CA</span>
              </template>
              <template #cell(log)="cell">
                <span>{{ (cell.item.log).toFixed(1) }}€</span>
                <br>
                <span class="text-primary">{{ ((cell.item.log * 100) / cell.item.ca).toFixed(1) }}% CA</span>
              </template>
              <template #cell(refund)="cell">
                <span>{{ (cell.item.refund) }}€</span>
                <br>
                <span class="text-primary">{{ ((Number(cell.item.refund) * 100) / mappedData.returnTotalEuro).toFixed(1) }}% Ret. / {{ ((Number(cell.item.refund) * 100) / cell.item.ca).toFixed(1) }}% CA.</span>
              </template>
              <template #cell(margin)="cell">
                <span>{{ (cell.item.ca - cell.item.cost - cell.item.log).toFixed(0) }}€</span>
                <br>
                <span class="text-success">{{ (((cell.item.ca - cell.item.cost - cell.item.log) * 100) / cell.item.ca).toFixed(1) }}%</span>
              </template>
            </b-table>

          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardBody, BCardHeader, BTable, BBadge, BButton,

} from 'bootstrap-vue'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
// import ChartjsComponentLineChart from '@core/components/charts-components/ChartjsComponentLineChart.vue'
// import ChartjsComponentBarChart from '@core/components/charts-components/ChartjsComponentBarChart.vue'
import JsonCSV from 'vue-json-csv'
import chartjsData from './chartjsData'

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    StatisticCardVertical,
    // ChartjsComponentLineChart,
    // ChartjsComponentBarChart,
    flatPickr,
    BTable,
    BBadge,
    // BTableSimple,
    // BThead,
    // BTr,
    // BTh,
    // BTd,
    // BTbody,
    vSelect,
    'download-csv': JsonCSV,
  },
  data() {
    return {
      data: {},
      mappedData: {},
      chartjsData,
      dashboard: {},
      dataProduct: [],
      dataChartLtv: {
        labels: [1, 2, 3, 4, 5],
        datasets: [],
      },
      categoryesLtv: [],
      productsOffer: [],
      totalDuoOffer: 0,
      tableColumns: [
        { key: 'mode', label: 'Catégorie', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'payment', label: 'CA', sortable: true },
        { key: 'cost', label: 'Coût Fab.', sortable: true },
        { key: 'log', label: 'Coût Log.', sortable: true },
        { key: 'margin', label: 'Marge', sortable: true },
      ],
      tableColumnsProduct: [
        { key: '_id', label: 'Product', sortable: true },
        { key: 'country', label: 'Pays', sortable: true },
        { key: 'ca', label: 'CA', sortable: true },
        { key: 'cost', label: 'Coût Fab.', sortable: true },
        { key: 'log', label: 'Coût Log.', sortable: true },
        { key: 'refund', label: 'Retours.', sortable: true },
        { key: 'margin', label: 'Marge', sortable: true },
      ],
      tableColumnsMarketing: [
        { key: '_id', label: 'Plateforme', sortable: true },
        { key: 'cost', label: 'Coût', sortable: true },
      ],
      country: 'DE',
      countryOptions: ['ES', 'FR', 'DE', 'IT'],
      productSelect: [],
      productList: [],
      rangeDate: null,
      dataChart: null,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  watch: {
    rangeDate: {
      handler: 'getData',
      deep: true,
    },
    productList: {
      handler: 'getData',
      deep: true,
    },
  },
  async created() {
    // data
    const filter = {}
    filter.created_at = { $gte: this.$moment().subtract(6, 'months').toDate().getTime(), $lte: this.$moment().endOf('days').toDate().getTime() }
    // eslint-disable-next-line object-curly-newline
    const { transactionData, mappedData, productsData, metricsData } = await this.$http.get('/admin/dashboard/ecommerce', { params: { filter, productList: this.productList } })
    this.transactionData = transactionData
    this.mappedData = mappedData
    this.productsData = productsData
    this.metricsData = metricsData
    const { products } = await this.$http.get('/admin/blog/list-product/?country=all')
    this.productSelect = products
  },
  methods: {
    async getData() {
      try {
        const filter = {}
        if (this.rangeDate && this.rangeDate.includes('to')) {
          const dates = this.rangeDate.split(' to ')
          filter.created_at = { $gte: this.$moment(dates[0]).startOf('days').toDate().getTime(), $lte: this.$moment(dates[1]).endOf('days').toDate().getTime() }
        // eslint-disable-next-line no-irregular-whitespace
        } else {
          filter.created_at = { $gte: this.$moment().subtract(6, 'months').toDate().getTime(), $lte: this.$moment().endOf('days').toDate().getTime() }
        }
        const {
          transactionData, mappedData, productsData, metricsData,
        } = await this.$http.get('/admin/dashboard/ecommerce', { params: { filter, productList: this.productList } })
        this.transactionData = transactionData
        this.mappedData = mappedData
        this.productsData = productsData
        this.metricsData = metricsData
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
